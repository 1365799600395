.sticky-footer{
    min-height: 94px;
    left:0;
    bottom:0;
    right:0;
    display: flex;
 flex-direction: column;
}
.sticky-footer{
  background-color: #910c19 !important;
}
.navbar{
    background-color: #910c19 !important;
    color: white !important;
  }
  .nav-link{
    color: white !important;
  }
  .nav-link :hover{
    color: gray !important;
  }
  .print{
    background-color: #910c19;
  }
  .getdata{
    background-color: #910c19;
  }
  .refresh{
    background-color: #910c19;
    color: white;
  }

  .card{
    background-color: #fdf3e3 !important;
  }
 
  td, th {
    background-color: #fdf3e3 !important;  
    padding: 15px;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    font-weight: bold;
    height: 65px !important;
}
/* .table-striped{
    border: 1px solid #000000 !important;
    border-left: 0;
    border-collapse: separate;
} */